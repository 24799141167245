<template>
  <static-template>
    <h1>About</h1>
  </static-template>
</template>

<script>
export default {
  name: 'about-page',
  data: () => ({}),
};
</script>
